import React from 'react'

import GitalkComponent from 'gitalk/dist/gitalk-component'

import '../styles/gitalk.css'

/*
  https://github.com/DQ-Lion/playground-site-comment/issues
*/

export default class PostPage extends React.Component {
  state = {
    com: null,
  }
  componentDidMount() {
    this.setState({
      com: (
        <GitalkComponent
          options={{
            clientID: 'cfaea2a9eeee80f4449a',
            clientSecret: '453cff4199999ba8abb605d39c299142a330e631',
            repo: 'playground-site-comment',
            owner: 'ubugio',
            admin: ['ubugio'],
            id: location.pathname.replace(/\/$/, ''), // Ensure uniqueness and length less than 50
            distractionFreeMode: false, // Facebook-like distraction free mode
          }}
        />
      ),
    })
  }
  render() {
    return this.state.com
  }
}
