import { includes, orderBy } from 'lodash'

export class SimilarArticlesFactory {
  // (1.) Create by passing in articles, currentSlug
  constructor(articles, currentArticleSlug) {
    // (2.) Don't include the current article in articles list
    this.articles = articles.filter(
      (aArticle) => aArticle.slug !== currentArticleSlug);

    this.currentArticleSlug = currentArticleSlug;
    // (3.) Set default values
    this.maxArticles = 3;
    this.category = null;
    this.keywords = []
  }

  // (4.) Builder pattern usage
  setMaxArticles(m) {
    this.maxArticles = m;
    return this;
  }

  setCategory(acategory) {
    this.category = acategory;
    return this;
  }

  setKeywords(keywordsArray) {
    this.keywords = keywordsArray;
    return this;
  }

  getArticles() {
    const { category, keywords, articles, maxArticles } = this;
    // (5.) We use an Identity Map to keep track of score
    const identityMap = {};


    if (!!keywords === false || keywords.length === 0) {
      console.error('SimilarArticlesFactory: keywords not provided, use setKeywords().')
      return [];
    }

    if (!!category === false) {
      console.error('SimilarArticlesFactory: category not provided, use setCategory().')
      return [];
    }

    function getSlug(article) {
      return article.slug;
    }

    function addToMap(article) {
      const slug = getSlug(article);
      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          article: article,
          points: 0
        }
      }
    }

    // (7.) For category matches, we add 2 points
    function addcategoryPoints(article, category) {
      const categoryPoints = 2;
      const slug = getSlug(article);

      if (article.category === category) {
        identityMap[slug].points += categoryPoints;
      }
    }

    // (8.) For keywords matches, we add 1 point
    function addkeywordsPoints(article, keywords) {
      const tagPoint = 1;
      const slug = getSlug(article);

      if (article.keywords)
        article.keywords.forEach((aTag) => {
          if (includes(keywords, aTag)) {
            identityMap[slug].points += tagPoint;
          }
        })
    }


    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map((slug) => identityMap[slug]);
    }

    // (6.) Map over all articles, add to map and add points
    for (let article of articles) {
      addToMap(article);
      addcategoryPoints(article, category);
      addkeywordsPoints(article, keywords)
    }

    // (9.) Convert the identity map to an array
    const arrayIdentityMap = getIdentityMapAsArray();

    // (10.) Use a lodash utility function to sort them 
    // by points, from greatest to least
    const similarArticles = orderBy(
      arrayIdentityMap, ['points'], ['desc']
    )

    // (11. Take the max number articles requested)
    return similarArticles.splice(0, maxArticles);
  }
}