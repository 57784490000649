import React, {useCallback} from 'react'
import {css} from '@emotion/core'

import {roundRect, loadImage, showCanvas} from './helper'

import rewardImage from '../../images/reward.png'

export const showPostRewardCard = async () => {
  const image = await loadImage(rewardImage)

  const canvas = document.createElement('canvas')
  canvas.width = 1080
  canvas.height = (canvas.width * image.height) / image.width
  const ctx = canvas.getContext('2d')

  ctx.save()
  ctx.beginPath()
  roundRect(ctx, 0, 0, canvas.width, canvas.height, 20)
  ctx.clip()
  ctx.closePath()
  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height)
  ctx.restore()

  showCanvas(canvas)
}
