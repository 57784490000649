import React, {useCallback} from 'react'
import {css} from '@emotion/core'

export const roundRect = (ctx, x, y, w, h, r) => {
  var min_size = Math.min(w, h)
  if (r > min_size / 2) r = min_size / 2
  ctx.moveTo(x + r, y)
  ctx.arcTo(x + w, y, x + w, y + h, r)
  ctx.arcTo(x + w, y + h, x, y + h, r)
  ctx.arcTo(x, y + h, x, y, r)
  ctx.arcTo(x, y, x + w, y, r)
}
export function drawText(ctx, text, x, y, maxWidth, lineHeight) {
  let arrText = text.split('')
  let line = ''
  for (let n = 0; n < arrText.length; n++) {
    let testLine = line + arrText[n]
    let metrics = ctx.measureText(testLine)
    let testWidth = metrics.width
    if (testWidth > maxWidth && n > 0) {
      ctx.fillText(line, x, y)
      line = arrText[n]
      y += lineHeight
    } else {
      line = testLine
    }
  }
  ctx.fillText(line, x, y)
  return y + lineHeight
}

export function calcTextHeight(ctx, text, maxWidth, font, lineHeight) {
  let y = 0
  let arrText = text.split('')
  let line = ''
  ctx.font = font
  for (let n = 0; n < arrText.length; n++) {
    let testLine = line + arrText[n]
    let metrics = ctx.measureText(testLine)
    let testWidth = metrics.width
    if (testWidth > maxWidth && n > 0) {
      line = arrText[n]
      y += lineHeight
    } else {
      line = testLine
    }
  }
  return y + lineHeight
}
export const hide = cb => {
  const oldCover = document.querySelector('div#share-cover')
  if (oldCover) {
    oldCover.classList.remove('show')
    setTimeout(() => {
      document.body.removeChild(oldCover)
      if (cb) cb()
    }, 500 + 50) // #share-cover 的动画时间相同
  } else if (cb) cb()
}
export const loadImage = async src => {
  return new Promise((r, j) => {
    const image = new Image()
    image.onload = () => r(image)
    image.src = src
  })
}
export const showCanvas = canvas => {
  hide()

  const cover = document.createElement('div')
  cover.id = 'share-cover'

  const coverCloser = document.createElement('div')
  coverCloser.id = 'share-cover-close'
  coverCloser.onclick = () => {
    hide()
  }

  cover.appendChild(coverCloser)
  cover.appendChild(canvas)
  document.body.appendChild(cover)

  const detectHasCover = () => {
    if (!document.querySelector('#share-cover')) {
      window.requestAnimationFrame(detectHasCover)
    } else {
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          cover.classList.add('show')
        }, 0)
      })
    }
  }
  window.requestAnimationFrame(detectHasCover)
}
