import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { SimilarArticlesFactory } from './SimilarArticlesFactory'

const SimilarArticlesComponent = ({ articles }) => (
  <section>
    <h3>🔥 推荐阅读</h3>
    <ul>
      {articles.map((article, i) => (
        <li key={article.article.slug}><a href={article.article.slug}>{article.article.title}</a></li>
      ))}
    </ul>
  </section>
)

// (1.) Query for articles
export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarArticles {    
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              published: { ne: false }
            }
          }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
                categories
              }
              frontmatter {
                title
                date
                description
                keywords
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { category, keywords, currentArticleSlug } = props;

      // (2.) Marshall the response into articles
      const articles = getPostsFromQuery(data.allMdx);

      // (3.) Use a SimilarArticlesFactory to get my similar articles
      const similarArticles = new SimilarArticlesFactory(
        articles, currentArticleSlug
      )
        .setMaxArticles(4)
        .setCategory(category[0])
        .setKeywords(keywords)
        .getArticles()

      // (4.) Render it
      return (
        <SimilarArticlesComponent
          articles={similarArticles}
        />
      )
    }}
  />
)

function getPostsFromQuery(posts) {
  if (posts) {
    return posts.edges
      .map(edge => edge.node)
      .map(node => Object.assign({}, node.frontmatter, node.fields, { excerpt: node.excerpt }))
  }

  return []
}