/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import BottomHandler from './bottomHandler'
import { css } from '@emotion/core'
import { bpMaxSM } from '~lib/breakpoints'

export default function Toc({ headings, slug }) {
  const [isSmallView, setIsSmallView] = useState(false)

  useEffect(() => {
    const matcher = window.matchMedia('(max-width: 1320px)')
    setIsSmallView(matcher.matches)
    const handler = v => setIsSmallView(v.matches)
    matcher.addListener(handler)
    return () => matcher.removeListener(handler)
  }, [])

  const minDepth = Math.min(...headings.map(({ depth }) => depth))
  useEffect(() => {
    const scroll = () => {
      const els = headings.map(({ value }) => document.getElementById(encodeURIComponent(value)))
      const topMap = els.map(ele => (ele ? ele.getBoundingClientRect().top : 0))
      if (topMap.length > 0 && topMap[0] < 0) {
        const topPx = 10
        let currentInd = 0
        if (topMap[topMap.length - 1] < topPx) currentInd = topMap.length - 1
        else
          currentInd = topMap.findIndex(
            (v, i) => v < topPx && topMap[i + 1] && topMap[i + 1] > topPx,
          )

        document.querySelectorAll('#toc-panel a').forEach((ele, ind) => {
          ele.style.background = ind === currentInd ? '#4e4e4e26' : 'none'
        })
      }
    }
    document.addEventListener('scroll', scroll, false)
    return () => {
      document.removeEventListener('scroll', scroll, false)
    }
  }, [headings, slug])

  if (headings.length == 0) return null
  return (
    <>
      <div
        css={css`
          height: max-content;
          max-height: 80vh;
          overflow-y: auto;
          z-index: 1;
          line-height: 2.2em;

          font-size: 0.85em;
          grid-column: 4 / -1;
          padding: 10px;
          position: fixed;
          top: 120px;
          left: calc(50% + 514px);
          margin: 0;
          background: var(--bgcolor-post);
          width: 225px;
          border: 1px solid var(--color-line);
          @media (max-width: 1500px) {
            position: static;
            background: none;
            padding: 10px;
            border: none;
            max-height: initial;
            width: auto;
            display: block !important;
            margin: 0;
            border-bottom: 1px solid var(--color-line);
            margin-bottom: 20px;
          }
        `}
      >
        <nav id="toc-panel">
          {headings.map(({ value, depth }) => (
            <Link
              className="toc-link"
              key={value}
              to={`${slug}#${encodeURIComponent(value)}`}
              style={{ paddingLeft: `${depth - minDepth + 0.5}em` }}
              css={css`
                padding: 2px 4px;
                font-size: 13px;
                line-height: 21px;
                display: block;
                color: var(--text-color-toc);
              `}
            >
              {value}
            </Link>
          ))}
        </nav>
      </div>
    </>
  )
}
