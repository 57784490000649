import React, { useState, useEffect } from 'react'
import BottomHandler from './bottomHandler'

export default function Toc() {
  const [isShowTop, setisShowTop] = useState(false)

  useEffect(() => {
    const scroll = () => {
      setisShowTop(document.documentElement.scrollTop > 300)
    }
    document.addEventListener('scroll', scroll, false)
    return () => {
      document.removeEventListener('scroll', scroll, false)
    }
  }, [])

  return (
    <BottomHandler
      title="🔝"
      id="top-toggle"
      trigger={() => {
        document.documentElement.scrollTop = 0
      }}
      isSmallView={isShowTop}
    />
  )
}
