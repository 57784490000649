import React from 'react'
import { css } from '@emotion/core'
import { bpMaxMD, bpMaxSM } from '~lib/breakpoints'
import ubug from '../images/logos/ubug5.svg'

function BlogFooter() {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          paddingRight: 20,
        }}
      >
        <img
          src={ubug}
          alt="Ubug"
          css={css`
            width: 80px;
            border-radius: 50%;
            ${bpMaxSM} {
              width: 40px;
            }`
          }
        />
      </div>
      <div>
        <strong>Ubug</strong>
        {` 一个开发工程师，现居北京，全栈偏前端开发，工作目标是用高质量的技术和代码改变世界。兴趣广泛、想法多、喜欢折腾。`}
      </div>
    </div>
  )
}

export default BlogFooter
