import React, {useCallback} from 'react'
import {css} from '@emotion/core'

import QRCore from '../../lib/qrcode/qrcode'

import {roundRect, loadImage, showCanvas, calcTextHeight, drawText} from './helper'

export const showPostShareCard = async (title, description, banner, url) => {
  const canvas = document.createElement('canvas')
  canvas.width = 1080
  const ctx = canvas.getContext('2d')

  // 变量声明
  const fontFamily =
    'rubik,pt serif,system-ui,-apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif'
  const borderWidth = 20
  const imgW = canvas.width - borderWidth * 2
  const imgH = banner ? imgW / banner.childImageSharp.fluid.aspectRatio : 0
  const titleFS = 52
  const titleLH = 1.2
  const descFS = 38
  const descLH = 1.4
  const QRSize = 200
  const urlFS = 32
  const urlLH = 1.4
  const titleFont = `bold ${titleFS}px ${fontFamily}`
  const descFont = `${descFS}px ${fontFamily}`
  const urlFont = `${urlFS}px ${fontFamily}`

  function calcTotalHeight() {
    return (
      borderWidth +
      imgH +
      borderWidth +
      calcTextHeight(ctx, title, imgW, titleFont, titleFS * titleLH) +
      borderWidth +
      calcTextHeight(ctx, description, imgW, descFont, descFS * descLH) +
      borderWidth +
      QRSize +
      borderWidth * 3
    )
  }

  canvas.height = calcTotalHeight()

  ctx.beginPath()
  roundRect(ctx, 0, 0, canvas.width, canvas.height, borderWidth)
  ctx.closePath()
  ctx.fillStyle = 'white'
  ctx.fill()

  ctx.font = titleFont
  ctx.fillStyle = '#333'
  const titleBottom = drawText(
    ctx,
    title,
    borderWidth,
    imgH + borderWidth * 2 + titleFS,
    imgW,
    titleFS * titleLH,
  )

  ctx.font = descFont
  ctx.fillStyle = '#555'
  const descBottom = drawText(
    ctx,
    description,
    borderWidth,
    titleBottom + borderWidth,
    imgW,
    descFS * descLH,
  )

  const qrData = QRCore.create(url)
  const qrRawSize = qrData.modules.size
  const qrScale = QRSize / qrRawSize
  const qrImage = ctx.createImageData(QRSize, QRSize)
  for (let i = 0; i < QRSize; i++) {
    for (let j = 0; j < QRSize; j++) {
      let posDst = (i * QRSize + j) * 4
      const iSrc = Math.floor(i / qrScale)
      const jSrc = Math.floor(j / qrScale)
      const px = qrData.modules.data[iSrc * qrRawSize + jSrc]
      qrImage.data[posDst++] = px ? 0 : 255
      qrImage.data[posDst++] = px ? 0 : 255
      qrImage.data[posDst++] = px ? 0 : 255
      qrImage.data[posDst] = 255
    }
  }
  ctx.putImageData(qrImage, imgW - QRSize + borderWidth, descBottom - borderWidth)

  ctx.font = urlFont
  ctx.fillStyle = '#999'

  const urlWidth = imgW - QRSize - borderWidth
  const urlHeight = calcTextHeight(ctx, url, urlWidth, urlFont, urlFS * urlLH)
  const urlTop = descBottom + QRSize + borderWidth * 2 - urlHeight
  drawText(ctx, url, borderWidth, urlTop, urlWidth, urlFS * urlLH)
  drawText(ctx, '@ubug', borderWidth, urlTop - urlFS * urlLH, imgW, urlFS * urlLH)

  showCanvas(canvas)

  if (banner) {
    const image = await loadImage(banner.childImageSharp.fluid.src)
    ctx.save()
    ctx.beginPath()
    roundRect(ctx, borderWidth, borderWidth, imgW, imgH, borderWidth)
    ctx.clip()
    ctx.closePath()
    ctx.drawImage(image, 0, 0, image.width, image.height, borderWidth, borderWidth, imgW, imgH)
    ctx.restore()
  }
}
