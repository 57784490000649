import React from 'react'
import { css } from '@emotion/core'
import { bpMaxSM } from '~lib/breakpoints'

function BottomHandler({
  title,
  trigger,
  isSmallView,
  id,
  color = '#ffc107',
  bottom = 40,
  hideWhenSmallScreen = false,
}) {
  return (
    <div
      css={css`
        .show {
          transform: translateX(50%);
        }
        .show:hover {
          transform: translateX(0%);
        }
      `}
    >
      <div
        onClick={trigger}
        className={`${isSmallView ? 'show' : ''}`}
        id={id}
        css={css`
          position: fixed;
          right: 0;
          bottom: 80px;
          background: #ffc107;
          color: #fff;
          padding: 6px 13px;
          border-radius: 20px 0 0 20px;
          width: 85px;
          transform: translateX(100%);
          transition: transform 0.3s;
          cursor: pointer;
          font-size: 13px;
          @media (max-width: 1200px) {
            display: none !important;
          }
        `}
        style={{
          background: color,
          bottom: `${bottom}px`,
        }}
      >
        {title}
      </div>
    </div>
  )
}
export default BottomHandler
