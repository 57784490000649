import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ExtendedMdxRender from '../components/extendedMdxRender'
import SEO from '../components/seo'
import { css } from '@emotion/core'
import Container from '../components/container'
import Layout from '../components/layout'
import AfterArticle from '../components/after-article'
import BlogPostFooter from '../components/blog-post-footer'
import SimilarArticles from '../components/SimilarArticles/SimilarArticles'
import SimpleKeywords from '../components/simple-keywords'
import SimpleCategory from '../components/simple-cat'
import Markdown from 'react-markdown'
import config from '../../config/website'
import theme from '../../config/theme'
import { bpMaxMD, bpMaxSM } from '~lib/breakpoints'
import get from 'lodash/get'

import Toc from '../components/handlers/toc'
import Top from '../components/handlers/top'

import Gitalk from '../components/GitTalk'

export default class PostPage extends React.Component {
  componentDidMount() {
    const {
      data: { mdx },
    } = this.props
    const { tips } = mdx.fields

    window.postTips = tips
    if (window.alertText && window.postTips.length) {
      window.alertText(tips[0])
    }

    if (location.hash !== '') {
      const dom = document.getElementById(location.hash.substr(1))
      if (dom) {
        if (dom.scrollIntoView) dom.scrollIntoView()
        else if (dom.getBoundingClientRect)
          document.scrollingElement.scrollTop += dom.getBoundingClientRect().top
      }
    }
  }
  render() {
    return <Post {...this.props} />
  }
}

function Post({ data: { site, mdx }, pageContext: { prev, next } }) {
  const {
    title,
    slug,
    description,
    datetime,
    tips,
    banner,
    author,
    categories,
    series,
    bannerCredit,
    noFooter,
    keywords,
    toc,
  } = mdx.fields

  const headings = mdx.headings

  const blogPostUrl = `${config.siteUrl}${slug}`

  const outdated = new Date().getTime() - new Date(datetime).getTime() > 365 * 24 * 60 * 60 * 1000

  return (
    <Layout headerColor={theme.colors.white} site={site} frontmatter={mdx.fields} headerLink="/blogs" noFooter={noFooter}>
      <SEO
        frontmatter={mdx.fields}
        metaImage={get(mdx, 'fields.banner.childImageSharp.fluid.src')}
        isBlogPost
      />
      <article
        data-tips={JSON.stringify(tips)}
        css={css`
          width: 100%;
          margin-top: 20px;
          twitter-widget {
            margin-left: auto;
            margin-right: auto;
          }
        `}
        className="article-content"
      >
        <Container
          css={css`
            padding-top: 20px;
            font-size: 17px;
            background: var(--bgcolor-post);
          `}
        >
          <h1
            css={css`
              text-align: center;
              margin-bottom: 20px;
              margin-top: 0;
            `}
          >
            {title}
          </h1>
          {banner && (
            <div
              css={css`
                text-align: center;

                p {
                  margin-bottom: 0;
                }
                ${bpMaxSM} {
                  padding: 0;
                }
              `}
            >
              <Img
                fluid={banner.childImageSharp.fluid}
                alt={site.siteMetadata.keywords.join(', ')}
              />
              <div
                css={css`
                  opacity: 0.6;
                  margin: 10px;
                  font-size: 12px;
                `}
              >
                {bannerCredit ? <Markdown linkTarget="_blank">{bannerCredit}</Markdown> : null}
              </div>
            </div>
          )}
          <br />
          <div
            css={css`
              font-size: 14px;
              color: #8e8e8e;
              margin-bottom: 10px;
              border-bottom: 1px solid var(--color-line);
              padding-bottom: 10px;
            `}
          >
            {/* <span>最后编辑于：{datetime}</span> */}
            <div id="post-article-start">
              <div>
                <span
                  css={css`
                    color: var(--color-anchor);
                    margin-left: 5px;
                  `}
                >
                  {author.toLowerCase() === 'ubug' ? '[原创]' : '[转载]'} &nbsp;
                </span>
                最后编辑于：{datetime}
                {/* background: #d6d6d6;padding: 2px 4px;margin: 0 3px; */}
                {/* background: #eaeaea;padding: 2px 4px; */}
                <SimpleCategory categories={categories} />
                <SimpleKeywords keywords={keywords} />
              </div>
            </div>
          </div>
          {outdated && (
            <p
              css={css`
                text-align: right;
                color: #ff9800;
                font-size: 13px;
              `}
            >
              !本篇文章过于久远，其中观点和内容可能已经不准确，请见谅!~
            </p>
          )}
          <Toc {...{ headings, slug, toc }} />
          <div className="with-mark-link">
            <ExtendedMdxRender>{mdx.body}</ExtendedMdxRender>
            <div css={{ textAlign: 'left', fontSize: 13 }}>
              <p css={{ margin: '0 0 10px' }}>
                感谢您的阅读，本文由 Ubug 版权所有。如若转载，请注明出处：Ubug（
              <a href={blogPostUrl}>{blogPostUrl}</a>）
            </p>
            </div>
          </div>
        </Container>
      </article>
      <Top />
      <Container noVerticalPadding>
        <AfterArticle url={blogPostUrl} title={title} slug={slug} description={description} banner={banner} />
      </Container>
      <Container
        css={css`
          padding: 10px 40px;
        `}
        className="with-mark-link"
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            ${bpMaxSM} {
              display: block;
              &>div{
                margin: 15px 0;
              }
            }
          `}
        >
          <div>
            {prev ? (
              <div>
                下一篇：<a href={prev.fields.slug}>{prev.fields.title}</a>
              </div>
            ) : (
                <div></div>
              )}
          </div>
          <div>
            {next ? (
              <div>
                上一篇：<a href={next.fields.slug}>{next.fields.title}</a>
              </div>
            ) : (
                <div></div>
              )}
          </div>
        </div>

        <SimilarArticles category={categories} keywords={keywords} currentArticleSlug={slug} />
      </Container>
      <Container>
        <BlogPostFooter />
      </Container>
      <Container>
        <Gitalk />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        keywords
      }
    }
    mdx(fields: {id: {eq: $id}}) {
      fields {
        title
        tips
        categories
        datetime
        noFooter
        description
        plainTextDescription
        author
        banner {
          ...bannerImage2000
        }
        bannerCredit
        slug
        keywords
      }
      headings {
        value
        depth
      }
      body
    }
  }
`
